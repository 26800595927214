import Rellax from 'rellax'

export const onRouteUpdate = ({ location, prevLocation }) => {
  const body = document.querySelector('body')
  body.classList.contains('no-scroll') && body.classList.remove('no-scroll')

  if (window.innerWidth >= 1024) {
    const rellaxElements = document.querySelectorAll('.rellax')

    if (rellaxElements.length > 0) {
      const rellax = new Rellax('.rellax', {
        center: true,
      })

      window.addEventListener('resize', () => {
        if (rellax) {
          window.innerWidth < 1024 ? rellax.destroy() : rellax.refresh()
        }
      })
    }
  }
}
